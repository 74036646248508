<template>
  <div>
    <Breadcrumbs
      src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/top-banner-mcommunities.jpg"
      breadcrumb="M-CHANGA Communities"
      title="M-CHANGA Communities"
    />
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <div class="row mchanga-communities-wrapper">
            <div class="col-lg-4 col-md-6" v-for="n in communityList" :key="n">
              <div class="mchanga-community-item">
                <img
                  :src="n.community_image"
                  class="community-logo mx-auto"
                />
                <h3 class="community-title">{{n.community_name}}</h3>
                <div class="community-statistic">
                  <div class="statistic-item">
                    <div class="counter">
                      <number
                      class="bold transition"
                      from=0
                      :format="theFormat"
                      :to="n.myCount"
                      :duration="duration/2"
                      easing="Circ.easeOut"/>
                    </div>
                    <p>Fundraisers</p>
                  </div>
                  <div class="statistic-item">
                    <div class="counter">
                      <number
                      v-if="n.curr_config.currency_code == 'KES'"
                      class="bold transition"
                      :from=0
                      :format="theFormat"
                      :to="n.raisedAmount"
                      :duration="duration/2"
                      easing="Circ.easeOut"/>
                      <number
                      v-else
                      class="bold transition"
                      :from=0
                      :format="theFormat"
                      :to="n.ex_rates.display_amount"
                      :duration="duration/2"
                      easing="Circ.easeOut"/>
                    </div>
                    <p>Raised ({{n.curr_config.currency_code}})</p>
                  </div>
                </div>
                <a :href="'/mchanga-community/' + n.comm_acct_no" class="button button-outline">Explore Community</a>
              </div>
            </div>
            <div class="col-md-12 mchanga-communities-footer">
              <!-- <Pagination /> -->
              <router-link to="/communities" class="transition-all"
                >Learn More About Communities &raquo;</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <QuestionBottom />
    </section>
    <Footer />
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import QuestionBottom from "../components/QuestionBottom.vue";
import Pagination from "../components/Pagination.vue";
import Footer from "../components/Footer/index.vue";
export default {
  components: {
    Breadcrumbs,
    QuestionBottom,
    Pagination,
    Footer,
  },

  data() {
    return {
      communityList: [],
      currency: "KES"
    }
  },

  async created() {
      const response = await fetch(process.env.VUE_APP_ROOT_API+`/service/fundraiser/community`)
        .then(async response => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          const sortedData = data.sort((a,b) => (a.raisedAmount < b.raisedAmount) ? 1 : ((b.raisedAmount < a.raisedAmount) ? -1 : 0))
          this.communityList = sortedData
        })
        .catch(error => {
          this.errorMessage = error;
          console.error('There was an error!', error);
        });
  }
};
</script>

